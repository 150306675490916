@import url(https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&family=Mochiy+Pop+One&display=swap);
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Cabin", sans-serif;
}

img {
  max-width: 100%;
}

img {
  max-height: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.heading1 {
  color: #0d8ccd;
  font-size: 16px;
  background-color: #000;
  display: flex;
  justify-content: center;
}

.textbox h1 {
  color: #ffffff;
}

.posrel {
  position: relative;
}

.posrel h2 {
  font-size: 27px;
}

.text-black {
  color: #faf9f9;
}

.nftData {
  margin-top: 10px !important;
  display: block;
  font-size: 14px;
  color: #898989;
}

.nftData span {
  color: #000;
}

.nftData span.orange {
  color: #eb4d00;
}

.roundBox {
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  border: 2px solid #bdbcbc;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 4px 12px;
}

.roundBox svg {
  font-size: 18px;
}

.roundBox h5 {
  margin-left: 10px;
  font-size: 16px;
  color: #3d3d3d;
}

.smallfont {
  font-size: 14px;
  color: #cecccc !important;
  font-weight: 300 !important;
  font-family: Cabin;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex.justifyEnd {
  justify-content: flex-end;
}

.d-flex.flexStart {
  justify-content: flex-start;
}

img.check_icon2 {
  position: absolute;
  top: 0;
  right: 0px;
  width: 20px !important;
  height: 20px !important;
}

.following {
  width: 35px !important;
  height: 35px !important;
}

.following img.check_icon2 {
  width: 13px !important;
  right: -4px;
  height: auto !important;
}

.edition {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin: 0 !important;
}

.edition small {
  margin: 0;
}

.timeingList {
  display: flex;
  align-items: flex-start;
}

.timeingList li {
  display: block;
  text-align: center;
}

.timeingList li h5 {
  font-weight: 600;
  font-size: 20px;
  color: #fdfdfd;
}

.timeingList li p {
  padding-top: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #eb4d00;
}

.modalTitle {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  color: #eb4d00;
  margin-bottom: 15px !important;
}

.checktoggel {
  position: relative;
}

.checktoggel .checktoggel2 {
  position: absolute;
  top: 0;
  right: 0;
}

.checktoggel .checktoggel2 label {
  padding: 0;
}

.modal_button_div button {
  margin-bottom: 20px !important;
}

.TabButtonsBox button {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #939393;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  margin-right: 4px;
}

.TabButtonsBox button span:last-child {
  font-weight: 400;
  padding-left: 5px;
  color: #575b61;
}

.TabButtonsBox button.active {
  background-color: transparent;
  color: #fcf9f9;
  border-color: #eb4d00;
}

.TabButtonsBox button:hover {
  background-color: transparent;
  color: #ff56f6;
  border-color: #eb4d00;
}

@media (max-width: 1024px) {
  .TabButtonsBox {
    overflow-x: auto;
    width: 100%;
    display: flex;
  }
  .TabButtonsBox button {
    white-space: nowrap;
  }
}

@media (max-width: 767px) {
  .TabButtonsBox {
    overflow-x: auto;
    width: 100%;
    display: block;
  }
  .TabButtonsBox button {
    white-space: nowrap;
  }
}

.bgblack {
  background-color: #000 !important;
}

.mainHeading {
  color: #fff;
}

h4.accordianText {
  font-size: 16px;
  font-weight: 400;
}

.usersView {
  margin-bottom: 15px;
  position: relative;
}

.usersView .figure {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  position: relative;
  max-height: 300px;
  overflow: hidden;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.07);
}

.usersView .figure.profilePicture img {
  min-width: 800px;
  width: 100%;
}

.usersView .figure img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

.usersView .usersPic {
  position: relative;
  margin-top: -80px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.usersView .usersPic figure {
  border-radius: 50%;
  border: 2px solid #f1f1f1;
  width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  margin: 0px auto 13px;
}

.usersView .usersPic figure img.check_icon {
  position: absolute;
  width: auto !important;
  height: auto !important;
  right: 0px;
  top: 4px;
}

.usersView .usersPic figure img {
  width: 100%;
  border-radius: 50%;
}

.User_card {
  display: flex;
  align-items: center;
}

.User_card div h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 7px;
}

@media (max-width: 768px) {
  .User_card div h5 {
    font-size: 14px;
    line-height: 20px;
  }
}

.User_card div p {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #fffbfb;
}

.User_card div a {
  color: #eb4d00;
}

.NftBidValue {
  color: #f5f5f5;
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  margin-top: 8px;
  display: flex;
}

.NftBidValue img {
  width: 15px;
  margin-right: 5px;
}

.bidTime {
  font-size: 13px;
}

@media (max-width: 767px) {
  .setPrice {
    border-radius: 10px;
    display: block;
    text-align: center;
  }
  .bid div::after {
    display: none;
  }
  .User_card div {
    padding-left: 10px;
    width: 75%;
  }
  .share_Box div {
    height: 40px;
    margin-left: 5px;
    width: 40px !important;
  }
  .share_Box {
    right: 0;
  }
  .share_Box div:last-child .toggel_box {
    width: 150px !important;
  }
  .share_Box div:first-child .toggel_box {
    width: 270px !important;
    right: 20px !important;
    top: -160px !important;
  }
  .share_Box ul li label {
    width: 30px;
    height: 30px;
  }
  .share_Box div:first-child .toggel_box {
    width: 300px !important;
    right: -41px;
    z-index: 9;
    top: 59px;
  }
  .order2 {
    order: 2;
  }
  .order1 {
    order: 1;
  }
  .information {
    right: 11px;
    left: auto;
    width: 200px;
    z-index: 3;
    top: -55px;
  }
}

figure.user_img {
  position: relative;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 0;
  margin-right: 10px;
}

figure.user_img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.User_card {
  display: flex;
  align-items: center;
}

.User_card div {
  width: 70%;
}

.User_card div h5 {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 7px;
}

@media (max-width: 768px) {
  .User_card div h5 {
    font-size: 14px;
    line-height: 20px;
  }
}

.User_card div h5 small a {
  color: #eb4d00;
}

.User_card div p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #aaaaaa;
}

.bid p {
  font-weight: 500;
  font-size: 24px;
  line-height: 21px;
  color: #fff;
  margin-bottom: 7px;
}

.bid h6 {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  margin-bottom: 15px;
}

.bid h6 span {
  color: #eb4d00;
}

.bid div {
  position: relative;
}

.bid div::after {
  content: "";
  border-right: 1px solid #d1d0d0;
  height: 80%;
  right: 17px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.bid div:last-child::after {
  display: none;
}

.logoImg {
  width: 75px;
  margin: 14px 15px 11px 0px;
  object-fit: contain;
}

@media (max-width: 1219px) {
  .logoImg {
    margin: 11px 1px 3px 13px;
    width: 52px;
  }
}

@media (max-width: 500px) {
  .logoImg {
    margin: 11px 1px 3px 13px;
    width: 52px;
  }
}

.CardBox {
  padding: 0 0px 20px;
  margin: 0px 0;
}

.share_Box {
  margin-top: 25px;
}

.share_Box ul {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
}

.share_Box ul li {
  width: 25%;
  text-align: center;
  display: block;
  cursor: pointer;
}

.share_Box ul li label {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ccc;
  padding: 0;
  color: #fff;
  margin: auto;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.share_Box ul li h6 {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 21px !important;
  color: #898989;
}

.share_Box div {
  margin-left: 20px;
  margin-left: 20px;
  height: 50px;
  width: 50px !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share_Box div:first-child {
  background: #03a9f4;
}

.share_Box div:first-child .toggel_box {
  width: 300px !important;
  right: 44%;
  z-index: 9;
  top: -170px;
}

.share_Box div:last-child {
  background: #898989;
}

.share_Box div:last-child .toggel_box {
  width: 200px !important;
  right: -10px;
}

.share_Box div button {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.share_Box div .toggel_box {
  background: rgba(32, 8, 59, 0.75) !important;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 15px !important;
  position: absolute;
  color: #faf8f8;
  top: -80px;
  margin: 0 !important;
  height: auto !important;
  text-align: center;
  display: block;
  padding: 15px;
}

.share_Box div .toggel_box.toggel_box::after {
  content: "";
  border-top: solid 10px rgba(32, 8, 59, 0.75) !important;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  bottom: -10px;
  position: absolute;
  right: 25px;
}

.usersView {
  margin-bottom: 15px;
  position: relative;
}

.usersView .figure {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  position: relative;
  max-height: 300px;
  overflow: hidden;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.07);
}

.usersView .figure.profilePicture img {
  min-width: 800px;
  width: 100%;
}

.usersView .figure img {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
}

.usersView .usersPic {
  position: relative;
  margin-top: -80px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.usersView .usersPic figure {
  border-radius: 50%;
  border: 2px solid #f1f1f1;
  width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  margin: 0px auto 13px;
}

.usersView .usersPic figure img.check_icon {
  position: absolute;
  width: auto !important;
  height: auto !important;
  right: 0px;
  top: 4px;
}

.usersView .usersPic figure img {
  width: 100%;
  border-radius: 50%;
}

.usersPic {
  padding: 20px;
  margin-top: -100px;
  text-align: center;
  color: #fff;
}

.usersPic p {
  font-size: 12px;
  color: #ccc;
}

.usersPicc {
  background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  -webkit-backdrop-filter: blur(42px);
          backdrop-filter: blur(42px);
  padding: 20px;
  margin-top: -100px;
  text-align: center;
  color: #fff;
}

.usersPicc p {
  font-size: 12px;
  color: #ccc;
}

small {
  font-size: 14px;
  color: #dfdfdf;
  font-weight: 400;
  font-family: Cabin;
  display: block;
}

small span {
  color: #f76c1c;
}

@media (max-width: 1280px) {
  .usersPicc {
    padding: 25px;
    margin-top: -48px;
    text-align: center;
    width: 201px;
    color: #fff;
  }
}

.socialLink {
  display: flex;
  justify-content: center;
}

.socialLink a {
  font-size: 16px;
  color: #fafafa;
  font-weight: 600;
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.socialLink svg {
  font-size: 22px;
  margin-right: 10px;
  margin-left: 15px;
}

.TabButtonsBox button {
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  margin-right: 4px;
}

.TabButtonsBox button span:last-child {
  font-weight: 400;
  padding-left: 5px;
  color: #cac8c8;
}

.TabButtonsBox button.active {
  background-color: transparent;
  color: #2ee9f6;
}

.TabButtonsBox button:hover {
  background-color: transparent;
  color: #2ee9f6;
}

.preiewBox {
  background: linear-gradient(45deg, #24232a, #0000);
  -webkit-backdrop-filter: blur(101px);
          backdrop-filter: blur(101px);
  border: 1px solid #898989;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 25px;
  height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.setPrice {
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  width: 100%;
  max-width: 50%;
  color: #fff;
  margin: 0 auto;
  margin-bottom: 30px;
}

.setPrice.active {
  background: linear-gradient(272.26deg, #e74c00 36.78%, #ff732c 86.13%);
  border: 2px solid #ee4e00;
}

.main-box h6 {
  color: #a5a2a2;
}

.gridview div {
  margin: 0 7px;
}

input:-webkit-autofill {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}

@media (max-width: 1280px) {
  .menuButton1 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .posrel h2 {
    line-height: 37px;
  }
}
/*# sourceMappingURL=main.css.map */
